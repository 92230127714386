import * as apiSubmitForm from '@/api/venue/submitForm'

export const ACTIONS = { SubmitForm: 'SubmitForm', }
export const MUTATIONS = {}

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async [ACTIONS.SubmitForm](_, dto) {
            const result = await apiSubmitForm.post(dto);

            if (result) {
                return result;
            }
        },
    }
};