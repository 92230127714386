<template>
  <div v-if="loading" class="loading-container">
    <div class="spinner-grow" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <router-view v-else :key="$route.fullPath" />
</template>

<script>
import { ref, onMounted } from 'vue'
//import { useStore } from 'vuex'

export default {
  name: 'App',
  components: {
  },
  setup() {
    const loading = ref(true)

    //const store = useStore()

    async function loadRequiredData() {
      loading.value = true

      //await store.dispatch('accounts/ensureUserAccounts');

      loading.value = false
    }

    onMounted(async () => {
      await loadRequiredData()
    })

    return {
      loading
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loading-container {
  padding-top: 80px;
}
</style>