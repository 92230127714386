import * as apiGetLegalDocument from '@/api/venue/getLegalDocument'

export const ACTIONS = { GetLegalDocument: 'GetLegalDocument', SetConsent: 'SetConsent', SetActiveDocument: 'SetActiveDocument' }
export const MUTATIONS = { GotLegalDocument: 'GotLegalDocument', "GotVenueStyling": "GotVenueStyling" }

export default {
    namespaced: true,
    state: {
        legalDocuments: [],
        venueStyling: null,
    },
    getters: {
        venueStyling: state => state.venueStyling,
        legalDocuments: state => {
            return state.legalDocuments
        },
        legalDocument: state => (id) => {
            return state.legalDocuments.find(e => e.id === id);
        },
    },
    mutations: {
        [MUTATIONS.GotLegalDocument](state, wrapper) {
            state.legalDocuments.push(wrapper);
        },
        [MUTATIONS.GotVenueStyling](state, styling) {
            state.venueStyling = {
                venueName: styling.venueName,
                venueLogo: styling.logoUrl,
                website: styling.website,
                backgroundImageUrl: styling.backgroundImageUrl,
            }
        },
    },
    actions: {
        async [ACTIONS.GetLegalDocument]({ commit }, { legalDocumentId, venueId }) {
            const result = await apiGetLegalDocument.get({ legalDocumentId: legalDocumentId, venueId: venueId });

            if (result) {
                commit(MUTATIONS.GotVenueStyling, result)
                commit(MUTATIONS.GotLegalDocument, result.doc)
                return result;
            }
        },
    }
};