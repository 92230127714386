import axios from 'axios'

//import { authPlugin as $auth } from '@/auth'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_CRM_API_BASE_URL,
  headers: {
    'apiKey': process.env.VUE_APP_CRM_API_KEY
  }
});

apiClient.interceptors.response.use(undefined, error => {
  console.log(error);

  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
      console.log('Warning - API:', data);
    /*notification.warning({
      message: data,
    })*/
  }
});

export default apiClient;