<template>
  <div class="mt-5">
    <p class="mb-2 px-2">{{ internalValue.label }}</p>
    <RadioGroup v-model="internalValue.answer">
      <RadioGroupLabel class="sr-only">Server size</RadioGroupLabel>
      <div class="space-y-2">
        <RadioGroupOption
          as="template"
          v-for="product in internalValue.products"
          :key="product.name"
          :value="product"
          v-slot="{ active, checked }"
        >
          <div
            :class="[
              active
                ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                : '',
              checked
                ? 'bg-selected bg-opacity-75 text-white '
                : 'bg-not-selected',
            ]"
            class="relative flex cursor-pointer px-5 py-4 focus:outline-none"
          >
            <div class="flex w-full items-center justify-between">
              <div class="flex items-center">
                <div class="text-sm">
                  <RadioGroupLabel
                    as="p"
                    :class="checked ? 'text-white' : 'text-gray-900'"
                    class="font-medium"
                  >
                    {{ product.name }}
                  </RadioGroupLabel>
                  <RadioGroupDescription
                    as="span"
                    :class="checked ? 'text-sky-100' : 'text-gray-500'"
                    class="inline"
                  >
                    <span> {{ product.price }}/{{ product.beforePrice }}</span>
                    <span aria-hidden="true"> &middot; </span>
                    <span>{{ product.description }}</span>
                  </RadioGroupDescription>
                </div>
              </div>
              <div v-show="checked" class="shrink-0 text-white">
                <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                  <circle
                    cx="12"
                    cy="12"
                    r="12"
                    fill="#fff"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M7 13l3 3 7-7"
                    stroke="#fff"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </div>
</template>

<script >
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  RadioGroupDescription,
} from "@headlessui/vue";
export default {
  name: "FormProductsSingle",
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupDescription,
    RadioGroupOption,
  },
  props: {
    modelValue: Object,
  },
  data: () => ({
    products: [
      {
        name: "Basic",
        ram: "1 GB",
        cpus: "1",
        disk: "25 GB",
      },
      {
        name: "Standard",
        ram: "2 GB",
        cpus: "2",
        disk: "50 GB",
      },
      {
        name: "Premium",
        ram: "4 GB",
        cpus: "4",
        disk: "100 GB",
      },
    ],
    selected: null,
  }),
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>
<style scoped>
.bg-selected {
  border-radius: 1rem;
  background-color: rgba(100, 106, 124, 1);
}
.bg-not-selected {
  border-radius: 1rem;
  border: 2px solid #e2e8f0;
  background-color: rgba(240, 242, 245, 1);
}
</style>