<template>
  <div class="floating-label-input mt-2">
    <input
      type="email"
      v-model="internalValue.answer"
      class="peer focus:outline-none border-none p-0"
      :placeholder="internalValue.label"
    />
    <label for="name" class="">{{ internalValue.label }}</label>
  </div>
</template>

<script >
export default {
  name: "FormEmail",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>
