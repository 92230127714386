<template>
  <div class="grid mx-5 md:mx-1" id="page" v-if="venueId">
    <div v-if="venueStyling" class="flex justify-center align-middle mt-6">
      <img
        style="max-height: 7rem; width: auto"
        :src="venueStyling.venueLogo"
      />
    </div>
    <div
      id="complianceform"
      class="
        sm:max-w-screen-sm
        md:max-w-screen-sm
        w-full
        mx-auto
        mt-6
        mb-10
        px-6
        sm:px-16
      "
    >
      <div class="text-center p-16" v-if="false">
        <!-- !form || !venueName -->
        <p class="opacity-60">{{ $t("message.loading") }}</p>
        <span class="flex h-12 w-12 mx-auto mt-5">
          <span
            class="
              animate-ping
              absolute
              inline-flex
              h-12
              w-12
              rounded-full
              bg-primary
              opacity-75
            "
          ></span>
          <span
            class="relative inline-flex rounded-full h-12 w-12 bg-primary"
          ></span>
        </span>
      </div>
      <div v-else>
        <h1 class="page-header mb-2">
          {{ form.publicFormName }}
        </h1>

        <div class="mb-8 flex" v-if="venueStyling">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 9.66662C9.38071 9.66662 10.5 8.54734 10.5 7.16663C10.5 5.78591 9.38071 4.66663 8 4.66663C6.61929 4.66663 5.5 5.78591 5.5 7.16663C5.5 8.54734 6.61929 9.66662 8 9.66662Z"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.7138 11.8808L9.17801 15.4167C8.8655 15.7289 8.44183 15.9042 8.00009 15.9042C7.55836 15.9042 7.13469 15.7289 6.82218 15.4167L3.28551 11.8808C2.3532 10.9485 1.71829 9.76058 1.46108 8.46739C1.20388 7.17419 1.33592 5.83376 1.84051 4.61561C2.34511 3.39745 3.19959 2.35628 4.29591 1.62376C5.39223 0.891229 6.68115 0.500244 7.99968 0.500244C9.31821 0.500244 10.6071 0.891229 11.7034 1.62376C12.7998 2.35628 13.6542 3.39745 14.1588 4.61561C14.6634 5.83376 14.7955 7.17419 14.5383 8.46739C14.2811 9.76058 13.6462 10.9485 12.7138 11.8808V11.8808Z"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="ml-1 text-lg" style="line-height: 1">
            {{ venueStyling.venueName }}
          </p>
        </div>

        <!-- FB Login -->
        <div
          class="fb-login-button"
          data-scope="public_profile,email"
          data-width=""
          data-size="large"
          data-button-type="continue_with"
          data-layout="default"
          data-onlogin="onFbLogin"
          data-auto-logout-link="false"
          data-use-continue-as="true"
        ></div>

        <!-- Component binding -->
        
        <component
          v-for="(field, fIdx) in form.fields"
          :key="fIdx"
          :hasGottenBirthday="hasGottenBirthday"
          v-model="form.fields[fIdx]"
          :is="'Form' + field.type"
          :currency="form.currency"
          :class="{ 'mt-8': fIdx === 0 }"
        />

        <!-- Consent (Legal documents) -->
        <legal-document
          v-for="(docWrapper, idx) in form.legalDocuments"
          v-model="form.legalDocuments[idx]"
          :venueId="venueId"
          :key="idx"
        />

        <!-- Totals -->
        <div class="flex justify-center mb-5 mt-12" v-if="hasProducts">
          <p class="totals">
            {{ $t("message.total") }} {{ totalSpend }} {{ form.currency }}
            <span v-if="form.minimumSpend > 0"
              >/ {{ form.minimumSpend }} {{ form.currency + " " }}
            </span>
            <span class="vat">{{ $t("message.tax") }}</span>
          </p>
        </div>

        <!-- Submit button  -->
        <div
          class="flex justify-center mb-12"
          :class="{ 'mt-12': !hasProducts }"
        >
          <button
            @click="submit()"
            :disabled="!isValid || isSubmitting"
            class="btn-cot"
            :style="
              !isValid || isSubmitting ? 'opacity: 0.5; cursor: default' : ''
            "
          >
            {{ form.submitButtonText }}
          </button>
          <p v-if="hasError">
            Something went wrong. If this continues, please contact us.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import { ref } from "vue";
import { mapGetters } from "vuex";
import { ArrowNarrowRightIcon, CheckIcon } from "@heroicons/vue/solid";
import {
  DialogPanel,
  TransitionRoot,
  TransitionChild,
  Dialog,
  //DialogTitle,
} from "@headlessui/vue";
import "v-calendar/dist/style.css";

// Static components
import LegalDocument from "@/components/LegalDocument.vue";

// Dynamic components
import FormText from "@/components/FormComponents/FormText.vue";
import FormDropdown from "@/components/FormComponents/FormDropdown.vue";
import FormTextarea from "@/components/FormComponents/FormTextarea.vue";
import FormDate from "@/components/FormComponents/FormDate.vue";
import FormNumber from "@/components/FormComponents/FormNumber.vue";
import FormProductsSingle from "@/components/FormComponents/FormProductsSingle.vue";
import FormProducts from "@/components/FormComponents/FormProducts.vue";
import FormEmail from "@/components/FormComponents/FormEmail.vue";
import FormPhone from "@/components/FormComponents/FormPhone.vue";

export default {
  components: {
    DatePicker,
    TransitionRoot,
    DialogPanel,
    TransitionChild,
    Dialog,
    ArrowNarrowRightIcon,
    CheckIcon,
    LegalDocument,
    FormText,
    FormDropdown,
    FormTextarea,
    FormDate,
    FormNumber,
    FormProductsSingle,
    FormProducts,
    FormEmail,
    FormPhone,
  },
  watch: {
    venueStyling() {
      if (this.venueStyling) {
        document.documentElement.style.setProperty(
          "--bg-img",
          `url(${this.venueStyling.backgroundImageUrl})`
        );
      }
    },
  },
  setup() {
    return {
      hasGottenBirthday: ref(false),
      isUsingFacebook: ref(false),
      venueId: ref(""),
      referralUserId: ref(""),
      form: ref({
        fields: [
          {
            id: "1",
            type: "Text",
            name: "name",
            answer: "",
            label: "Dit navn",
            isRequired: true,
          },
          {
            id: "2",
            name: "email",
            type: "Email",
            answer: "",
            label: "Din e-mail",
            isRequired: true,
          },
          {
            id: "3",
            type: "Phone",
            name: "phone",
            answer: "",
            countryCode: "+45",
            label: "Telefon nr.",
            isRequired: true,
          },
          {
            type: "Products",
            stringAnswer: "",
            products: [
              {
                id: "PRODUCT-24C6E",
                aggregateType: "bookingFormProduct",
                name: "Pakke 1",
                description:
                  "1 flaske husets spiritus (Vodka, Gin, Rom, Malibu, Safari mm.)\n1 mixer (Sprite, Fanta, Cola, Lemon, Juice mm.)\n1 stk 3L Pitcher med Gin Hass",
                price: 495,
                beforePrice: 900,
                imageUrl: null,
                quantity: 0,
                createdAction: {
                  userId: "080d15c0-63d8-4300-96dd-3985bf9edeb9",
                  userDisplayName: "Manja Burgdorff",
                  timestamp: "2022-09-23T12:04:56.3873654Z",
                },
                updatedAction: {
                  userId: "080d15c0-63d8-4300-96dd-3985bf9edeb9",
                  userDisplayName: "Manja Burgdorff",
                  timestamp: "2022-09-23T12:05:22.0267563Z",
                },
              },
              {
                id: "PRODUCT-2D41A",
                aggregateType: "bookingFormProduct",
                name: "Pakke 2",
                description:
                  "Vælg mellem 2 flasker Smirnoff, Pampero Blanco, Malibu eller Gordon's Gin og mix med eget valg af juice eller vand. 1 flaske Små shots varianter.",
                price: 895,
                beforePrice: 1150,
                imageUrl: null,
                quantity: 0,
                createdAction: {
                  userId: "080d15c0-63d8-4300-96dd-3985bf9edeb9",
                  userDisplayName: "Manja Burgdorff",
                  timestamp: "2022-09-23T12:12:55.7548004Z",
                },
                updatedAction: {
                  userId: "080d15c0-63d8-4300-96dd-3985bf9edeb9",
                  userDisplayName: "Manja Burgdorff",
                  timestamp: "2022-09-23T12:13:14.2127337Z",
                },
              },
            ],
            id: "c761431c-28b4-4e38-ac06-66579bc6c92c",
            technicalType: "Products",
            label: "Bordpakker",
            helpText: "",
            isRequired: false,
            isAnswered: false,
          },
        ],
        submitButtonText: "Sign up",
        publicFormName: "Sign up",
        legalDocuments: [
          {
            isAccepted: false,
            docId: "9d45bbc3-5a58-4e47-894f-7b92f6d46b98",
            //docId: "7c5e7ae8-4139-4e34-b6e9-13c57145ba09",
            displayText:
              "Samtykkeerklæring til behandling af personoplysninger.",
            isRequired: true,
          },
          {
            isAccepted: false,
            docId: "5b9cc140-1e4a-4fcd-bddc-7ebc080e9995",
            // docId: "7c5e7ae8-4139-4e34-b6e9-13c57145ba09",
            displayText:
              "Jeg giver mit samtykke til at modtage markedsføring og blive kontaktet via det sociale medie jeg har oplyst.",
            isRequired: false,
          },
        ],
      }),
      isSubmitting: ref(false),
      hasError: ref(false),
    };
  },
  computed: {
    ...mapGetters({
      // Consent
      legalDocuments: "legalDocuments/legalDocuments",
      venueStyling: "legalDocuments/venueStyling",
    }),
    isValid() {
      return (
        this.form.fields.every(
          (field) => !field.isRequired || field.answer !== ""
        ) &&
        this.form.legalDocuments.every(
          (doc) => !doc.isRequired || doc.isAccepted
        ) &&
        // check if email type is valid email
        this.form.fields
          .find((field) => field.type === "Email")
          .answer.includes("@")
      );
    },
    hasProducts() {
      return this.form.fields.some(
        (e) => e.type === "Products" || e.type === "ProductsSingle"
      );
    },
    totalSpend() {
      // TODO single product support
      if (!this.hasProducts) return 0;
      return this.form.fields
        .filter((e) => e.type === "Products")[0]
        .products.map((e) => e.quantity * e.price)
        .reduce((a, b) => a + b, 0);
    },
  },
  async mounted() {
    localStorage.setItem("rekomFbToken", "");
    this.referralUserId = this.$route.params.referralUserId;
    this.venueId = this.$route.params.venueId;
    this.checkToken();
    /*    console.log(
      "Loaded with form id",
      this.referralUserId,
      "venue id",
      this.venueId
    ); */
    //this.searchVenue(shortName);
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem("rekomFbToken");
      if (token && token != "") {
        /* document.getElementsByClassName("fb-login-button")[0].style.display =
          "none"; */
        this.isUsingFacebook = false;
        console.log("User logged in with Facebook");
        // eslint-disable-next-line
        FB.api("/me?fields=email,name,birthday", (response) => {
          this.hasGottenBirthday = true;
          this.form.fields.find((e) => e.name === "name").answer =
            response.name;
          this.form.fields.find((e) => e.name === "email").answer =
            response.email;
          this.form.fields.find((e) => e.name === "birthDate").answer =
            response.birthday;
          this.form.fields.find((e) => e.name === "facebook").answer =
            response.name;
        });
      } else {
        setTimeout(() => this.checkToken(), 100);
      }
    },
    async onFbLogin(o) {
      console.log("Fb login complete", o);
    },
    async submit() {
      this.isSubmitting = true;

      const dto = {
        venueId: this.venueId,
        referralUserId: this.referralUserId,
        name: this.form.fields.find((e) => e.name === "name").answer,
        email: this.form.fields.find((e) => e.name === "email").answer,
        phone: this.form.fields.find((e) => e.name === "phone").answer,
        phoneCountryCode: this.form.fields.find((e) => e.name === "phone")
          .countryCode,
        birthDate: this.form.fields.find((e) => e.name === "birthDate").answer,
        instagram: this.form.fields.find((e) => e.name === "instagram").answer,
        facebook: this.form.fields.find((e) => e.name === "facebook").answer,
        legalDocuments: this.form.legalDocuments
          .filter((e) => e.isAccepted)
          .map((e) => e.docId),
      };

      await this.$store.dispatch("form/SubmitForm", dto);
      this.isSubmitting = false;

      // use router to redirect to form-submitted
      /* this.$router.push({
        name: "form-submitted",
        params: {
          referralUserId: this.referralUserId,
          venueId: this.venueId,
        }, 
      }); */
      //
      const website = this.venueStyling.website;

      if (website && website !== "") {
        if (!/^https?:\/\//i.test(website)) {
          window.location.href = "https://" + website;
        } else {
          window.location.href = website;
        }
      } else {
        this.$router.push("/form-submitted");
      }
    },
  },
};
</script>

<style>
:root {
  --bg-img: url("../assets/bg.jpg");
}

@media only screen and (min-width: 600px) {
  html {
    background: var(--bg-img) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

#complianceform {
  border-radius: 45px;
  background-color: white;
}

.totals {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.totals .vat {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: theme("colors.muted");
}

.subscript {
  opacity: 0.6;
}
</style>