import apiClient from '@/services/axios'

export async function get({ legalDocumentId, venueId }) {
    const url = "https://rekom-crm-functions-prod.azurewebsites.net/api/DynamicFormsGetLegalDocument"
    const params = `?venueid=${venueId}&docid=${legalDocumentId}`;
    var response = await apiClient.get(url + params);

    if (response) {
        console.log(response)
        return response.data;
    }

    throw new Error('Unable to get legal doc')
}