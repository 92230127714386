import apiClient from '@/services/axios'

export async function post(dto) {
    const url = "https://rekom-crm-functions-prod.azurewebsites.net/api/ComplianceFormSubmitted?code=jXBo1HvhJRhTkb8zdebwqzrSgXCwPfu1C53IwX6OEEgsAzFuWNvK4Q=="
    console.log("Posting", dto);
    var response = await apiClient.post(url, dto);

    if (response) {
        console.log(response)
        return response.data;
    }

    throw new Error('Unable to post form')
}