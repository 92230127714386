
<template>
  <div class="flex mt-6" v-if="venueId">
    <label class="flex items-center">
      <input
        class="w-7 h-7 min-w-7 min-h-7 form-checkbox"
        type="checkbox"
        :required="internalValue.isRequired"
        v-model="internalValue.isAccepted"
        @click="toggleConsent()"
      />
      <span class="ml-2 text-sm">{{ internalValue.displayText }}</span>
    </label>
  </div>
  <TransitionRoot appear :show="isOpen" as="template" v-if="venueId">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-opacity-25 bg-black-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="
                w-full
                max-w-xl
                transform
                overflow-hidden
                rounded-3xl
                bg-white
                p-6
                text-left
                align-middle
                shadow-xl
                transition-all
              "
              style="
                padding-top: 5rem;
                padding-right: 3.5rem;
                padding-left: 3.5rem;
              "
            >
              <!--<DialogTitle
                      as="h3"
                      class="text-lg font-medium leading-6 text-gray-900"
                    >
                      Please review this document
                    </DialogTitle>-->

              <div style="position: relative">
                <div
                  style="max-height: 500px; overflow: auto"
                  v-if="legalDocument"
                  @scroll="onScroll"
                  id="legalDocument"
                  v-html="legalDocument.documentHtml"
                ></div>
                <div class="popup-scroll-fade" v-if="!scrolledToBottom"></div>
                <div class="popup-scroll-fade-btn" v-if="!scrolledToBottom">
              <!--     <button style="cursor: default" class="">
                    <img src="../assets/arrow_down_btn.png" />
                  </button> -->
                </div>
              </div>

              <button @click="closeModal(false)" class="btn-popup-close">
                <img src="../assets/cancel_btn.png" />
              </button>

              <!-- <p class="text-sm text-gray-500">
                        Lorem ipsum dolor sit amet.
                      </p>-->

              <div class="flex justify-center mt-6">
                <button
                  @click="closeModal(true)"
                  :disabled="!scrolledToBottom"
                  style="position: relative"
                  class="btn-cot"
                  :style="
                    !scrolledToBottom ? 'opacity: 0.5; cursor: default' : ''
                  "
                >
                  Accept
                  <div
                    style="
                      display: inline;
                      position: absolute;
                      right: 1rem;
                      top: -webkit-calc(50% - (1.25rem/2));
                      top: -moz-calc(50% - (1.25rem/2));
                      top: calc(50% - (1.25rem / 2));
                    "
                  >
                    <ArrowNarrowRightIcon class="h-5 w-5" />
                  </div>
                </button>
              </div>
              <div class="flex justify-center mt-4">
                <a
                  class="
                    text-gray-700
                    font-medium
                    text-sm
                    hover:bg-blue-200
                    cursor-pointer
                  "
                  @click="closeModal(false)"
                  >Cancel</a
                >
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ArrowNarrowRightIcon } from "@heroicons/vue/solid";
import {
  DialogPanel,
  TransitionRoot,
  TransitionChild,
  Dialog,
  //DialogTitle,
} from "@headlessui/vue";

export default {
  name: "LegalDocument",
  components: {
    ArrowNarrowRightIcon,
    DialogPanel,
    TransitionRoot,
    TransitionChild,
    Dialog,
  },
  props: {
    modelValue: Object,
    venueId: String,
  },
  emits: ["update:modelValue"],
  data: () => ({
    isOpen: false,
    scrolledToBottom: false,
  }),
  methods: {
    autoScroll() {
      const objDiv = document.getElementById("legalDocument");
      objDiv.scrollTop = objDiv.scrollHeight;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= (scrollHeight * 0.99)) {
        this.scrolledToBottom = true;
      } else {
        //this.scrolledToBottom = false;
      }
    },
    toggleConsent() {
      if (!this.internalValue.isAccepted) {
        this.scrolledToBottom = false;
        this.isOpen = true;
      }
    },
    closeModal(consentState) {
      this.internalValue.isAccepted = consentState;
      this.isOpen = false;
    },
    openModal() {
      this.isOpen = true;
    },
  },
  mounted() {
    console.log(
      "Legal document component mounted with",
      this.internalValue,
      "venueid",
      this.venueId
    );
    this.$store.dispatch("legalDocuments/GetLegalDocument", {
      legalDocumentId: this.internalValue.docId,
      venueId: this.venueId,
    });
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
    legalDocument() {
      return this.$store.getters["legalDocuments/legalDocument"](
        this.internalValue.docId
      );
    },
  },
};
</script>

<style scoped>
.subscript {
  opacity: 0.6;
}
.bg-black-opacity {
  background-color: rgba(0, 0, 0, 0.55);
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}
.btn-popup-close {
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
}
.btn-popup-close img {
  object-fit: cover;
  height: 30px;
  width: auto;
}

.popup-scroll-fade {
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  background-color: #fff;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
}
.popup-scroll-fade-btn {
  width: 50px;
  height: 50px;
  bottom: 0;
  margin-left: -25px;
  left: 50%;
  position: absolute;
}
</style>