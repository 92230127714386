import BookingFormSubmitted from '../src/views/BookingFormSubmitted.vue'
import BookingForm from '../src/views/BookingForm.vue'
import BookingFormFb from '../src/views/BookingFormFb.vue'
import { createWebHistory, createRouter } from "vue-router";
import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import store from './store';
import { createI18n } from 'vue-i18n'
//import { setupAuth } from './auth';

//import { routeGuard } from '@/auth'

const routes = [
  {
    path: '/products/:referralUserId/:venueId', // This is just to get app reviewed
    name: 'Booking form products',
    component: BookingFormFb,
    //beforeEnter: routeGuard
  },
  {
    path: '/:referralUserId/:venueId',
    name: 'Booking form',
    component: BookingForm,
    //beforeEnter: routeGuard
  },

  {
    path: '/form-submitted',
    name: 'Booking form submitted',
    component: BookingFormSubmitted,
    //beforeEnter: routeGuard
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

/*function callbackRedirect(appState) {
  router.push(
      appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
}*/

/*const authConfig = {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
  audience: process.env.VUE_APP_AUTH_AUDIENCE
}*/

/*setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app')
})*/


const messages = {
  en: {
    message: {
      loading: "Loading...",
      total: 'Total',
      tax: "incl. VAT",
      country: "Country",
      defaultCountryCode: "+44",
      legalAccept: "Accept",
      legalCancel: "Cancel",
      submittedTitle: "Thank you for signing up",
      submittedMessage: "We look forward to seeing you again.",
    }
  },
  "fi-FI": {
    message: {
      loading: "Loading...",
      total: 'Yhteensä',
      tax: 'sis. alv',
      country: "Maa",
      defaultCountryCode: "+358",
      legalAccept: "Acceptér",
      legalCancel: "Annullér",
      submittedTitle: "Thank you for signing up",
      submittedMessage: "We look forward to seeing you again.",
    }
  },
  "da-DK": {
    message: {
      loading: "Henter...",
      total: 'I alt',
      tax: "inkl. moms",
      country: "Land",
      defaultCountryCode: "+45",
      legalAccept: "Acceptér",
      legalCancel: "Annullér",
      submittedTitle: "Tak for din tilmelding.",
      submittedMessage: "Vi glæder os til at se dig igen.",
    }
  },
  no: {
    message: {
      loading: "Loading...",
      total: 'I alt',
      tax: "inkl. mva",
      country: "Land",
      defaultCountryCode: "+47",
      legalAccept: "Acceptér",
      legalCancel: "Annullér",
      submittedTitle: "Thank you for signing up",
      submittedMessage: "We look forward to seeing you again.",
    }
  },

}

const i18n = createI18n({
  //locale: 'fi-FI',
  locale: navigator.language || navigator.userLanguage,
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages

})


app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')